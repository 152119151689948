.menu-wrap {
  z-index: 1;
  text-align: center;
  align-items: center;
  position: relative;
}

.menu-wrap .hamburger {
  z-index: 1;
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
  position: relative;
}

.menu-wrap .hamburger .line--1 {
  width: 40px;
  height: 3px;
  background: #000;
  flex: none;
  justify-content: center;
  align-items: center;
  transition: all .4s;
  display: flex;
  position: relative;
}

.menu-wrap .hamburger .line--2 {
  z-index: 1;
  width: 35px;
  height: 3px;
  background: #000;
  transition: all .4s;
  position: absolute;
  top: 50px;
}

.menu-wrap .hamburger .line--3 {
  z-index: 1;
  width: 35px;
  height: 3px;
  background: #000;
  transition: all .4s;
  position: absolute;
  top: 30px;
}

#navbar.scrolled-nav ~ .menu {
  height: calc(100vh - 90px);
  top: 90px;
}

.menu {
  z-index: 5;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  width: 100vw;
  height: calc(100vh - 130px);
  background-color: #000000b3;
  padding-bottom: 60px;
  transition: all 1s;
  position: fixed;
  top: 130px;
  right: 0;
  overflow-y: scroll;
  transform: translateY(-150%);
}

.menu > div {
  align-items: top;
  flex: none;
  justify-content: center;
  display: flex;
}

.menu > div > div {
  text-align: center;
  max-width: 90vw;
  max-height: 100vh;
}

.menu > div > div > ul > li {
  color: #52b3de;
  border-bottom: 1px solid #444;
  padding: .8rem;
  font-size: 1.7rem;
  list-style: none;
}

.menu > div > div > ul > li:last-child {
  border-bottom: none;
}

.menu > div > div > ul > li > a {
  color: inherit;
  text-decoration: none;
  transition: color .4s;
}

/*# sourceMappingURL=index.7f8d8a03.css.map */
